import { cloneDeep, find } from "lodash-es";
import store from "@/store";

export default {
  addPriceProps(obj) {
    obj = cloneDeep(obj);

    if (obj.prices.length) {
      obj.prices.forEach(price => {
        obj[`price-${price.currency_id}`] = price.price;
      });
    } else {
      const currencies = store.getters["ancillaries/currencies"];

      currencies.forEach(c => {
        obj[`price-${c.id}`] = 0;
      });
    }

    return obj;
  },
  updatePrices(key, value, obj) {
    const currencies = store.getters["ancillaries/currencies"];

    const currency = find(
      currencies,
      c => c.id === parseInt(key.split("-")[1])
    );

    if (currency.code === obj.base_currency) {
      // Convert if base price not GBP
      if (currency.code !== "GBP") {
        value = value / currency.exchange_rate;
      }
      currencies.forEach(c => {
        if (key !== `price-${c.id}`) {
          const price = parseFloat(value) * c.exchange_rate;

          // All prices should either end in x.49 or x.99
          obj[`price-${c.id}`] =
            parseInt(
              price
                .toFixed(2)
                .toString()
                .split(".")[1]
            ) > 49
              ? parseInt(price) + 0.99
              : parseInt(price) + 0.49;
        }
      });
    }

    return obj;
  }
};
