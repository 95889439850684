import { forEach, cloneDeep, find, concat } from "lodash-es";
import salesChannelCategories from "@/fixtures/sales-channel-categories";
import store from "@/store";
import priceHelper from "./price";

export default {
  convertToAPIFormat(book) {
    book = cloneDeep(book);

    // Prices
    book.prices = [];
    forEach(book, (price, field) => {
      if (field.includes("price-")) {
        book.prices.push({
          currency_id: field.split("-")[1],
          price: parseFloat(price || 0).toFixed(2)
        });
      }
      return field;
    });

    // Keywords
    book.keywords = (book.keywords || []).join(",").trim();
    book.image_ai_tools = (book.image_ai_tools || []).join(",").trim();
    book.text_ai_tools = (book.text_ai_tools || []).join(",").trim();
    book.translation_ai_tools = (book.translation_ai_tools || [])
      .join(",")
      .trim();

    // ISBN Requested
    book.isbn_requested = !book.has_own_isbn;
    book.sales_channels = this.extractChannelIds(book.sales_channels);

    // Categories conversion
    book.categories = book.categories.map(c => c.id);

    book.territories = this.formatTerritoriesForAPI(book.territories);

    book.print_isbn = !book.print_isbn
      ? book.print_isbn
      : book.print_isbn.replace(/-/g, "");
    book.epub_isbn = !book.epub_isbn
      ? book.epub_isbn
      : book.epub_isbn.replace(/-/g, "");

    // Selected user for admin
    if (book.user) book.user_id = book.user.id;

    return book;
  },
  convertFromAPIFormat(book) {
    book = priceHelper.addPriceProps(book);

    if (!Array.isArray(book.keywords))
      book.keywords = (book.keywords || "").trim().split(",");

    if (!Array.isArray(book.image_ai_tools))
      book.image_ai_tools = (book.image_ai_tools || "").trim().split(",");

    if (!Array.isArray(book.translation_ai_tools))
      book.translation_ai_tools = (book.translation_ai_tools || "")
        .trim()
        .split(",");

    if (!Array.isArray(book.text_ai_tools))
      book.text_ai_tools = (book.text_ai_tools || "").trim().split(",");

    book.has_own_isbn = !book.isbn_requested;

    book.list_for_free =
      book.book_type === "ebook" ? book.list_for_free : false;

    // Sales channels
    book.sales_channels = this.groupChannelIdsByCategory(book.sales_channels);
    book.is_part_of_series = !!book.is_part_of_series;
    book.distribute_worldwide = !!book.distribute_worldwide;
    book.enrol_in_amazon_kdp_select = !!book.enrol_in_amazon_kdp_select;

    return book;
  },
  formatTerritoriesForAPI(territories) {
    const continents = store.getters["ancillaries/territories"];
    return concat(
      ...territories.map(t => {
        if (typeof t === "string") {
          return t.replace("-t", "");
        }

        return find(continents, c => c.id === t).children.map(c =>
          c.id.replace("-t", "")
        );
      })
    );
  },
  formatTerritories(territories) {
    let final = [];
    const continents = store.getters["ancillaries/territories"];
    const fullySelectedContinents = [];

    continents.forEach(c => {
      const selectedTerritoriesFromThisContinent = territories.filter(
        t => t.continent_id === c.id
      );
      if (selectedTerritoriesFromThisContinent.length === c.children.length) {
        fullySelectedContinents.push(c.id);
      }
    });

    final = fullySelectedContinents.concat(
      territories
        .filter(t => !fullySelectedContinents.includes(t.continent_id))
        .map(t => `${t.id}-t`)
    );

    return final;
  },
  optionizeChannels(channels) {
    return this.optionizeTreeSelect(channels, "name");
  },
  optionizeTreeSelect(channels, label) {
    const options = [];
    forEach(channels, (channels, category) => {
      options.push({
        id: category,
        label: window.$vm.$t(category),
        children: channels.map(c => ({ ...c, label: c[label] }))
      });
    });
    return options;
  },
  groupChannelIdsByCategory(selected) {
    const selectedIds = selected.map(c => c.id);
    const salesChannels = store.getters["ancillaries/salesChannelsCategorized"];
    let options = [];

    if (salesChannels) {
      forEach(salesChannels, (channels, category) => {
        const selectedChildren = channels
          .filter(channel => selectedIds.includes(channel.id))
          .map(c => c.id);

        if (selectedChildren.length === channels.length)
          return options.push(category);
        options = options.concat(selectedChildren);
      });
    } else {
      options = selected;
    }
    return options;
  },
  extractChannelIds(selected) {
    const salesChannels = store.getters["ancillaries/salesChannelsCategorized"];
    let ids = [];
    selected.forEach(id => {
      if (salesChannelCategories.includes(id)) {
        return (ids = ids.concat(salesChannels[id].map(c => c.id)));
      }

      ids.push(id);
    });

    return ids;
  },
  getISBN(book) {
    let isbn = null;
    if (book.type === "print") {
      isbn = book.print_isbn;
    } else {
      isbn = book.epub_isbn;
    }

    return isbn || book.asin;
  }
};
